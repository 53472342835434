import React, { useState, useEffect } from "react";

// utils
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import axios from "axios";

// components
import { Container, Row, Col } from "reactstrap";

//redux
import { useDispatch, useSelector } from "react-redux";
import { openModal as deprecatedOpenModal } from "../actions/modals";
import { ADD_WIDGET_MODAL } from "../modals/types";
import * as WIDGETS from "../constants/widgetsSiteDashboard";
import { siteRoutes } from "../helpers/apiRoutes";

import { openModal } from "../features/modals";

import WorkflowExecutionCount from "../components/Widgets/WorkflowExecutionCount";
import SimpleHeader from "../components/Headers/SimpleHeader";
import Skeleton from "react-loading-skeleton";
import ActionItems, { ActionItemTypes, Triggers } from "../components/ActionItems";
import { SITE_MODAL } from "../modals/types";

import VisitorsToday from "../components/Widgets/VisitorsToday";
import VisitorsWeek from "../components/Widgets/VisitorsWeek";

import TransportsToday from "../components/Widgets/TransportsToday";
import TransportsWeek from "../components/Widgets/TransportsWeek";
import { useAppDispatch } from "../hooks/redux";
import MODALS from "../constants/modals";

interface IWidget {
  _id: string;
  type: WIDGETS.WidgetTypes;
  workflow: string;
}

interface IParams {
  siteId: string;
}

export default function SiteDashboard() {
  const { siteId } = useParams<IParams>();
  const { t } = useTranslation();

  const deprecatedDispatch = useDispatch();

  const dispatch = useAppDispatch();

  // @ts-ignore TODO: useAppSelector
  const user = useSelector((state) => state.auth.user);

  const [widgets, setWidgets] = useState<IWidget[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [siteName, setSiteName] = useState<string>();

  useEffect(() => {
    onSubmitCallback();
    setLoading(true);
    axios
      .get(siteRoutes.get.site(siteId))
      .then((res) => {
        setSiteName(res.data.name);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const onSubmitCallback = () => {
    axios
      .get(siteRoutes.get.widgets(siteId))
      .then((res) => {
        setWidgets(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleRemove = (widgetId: string) => {
    axios
      .delete(siteRoutes.delete.widget(siteId, widgetId))
      .then((res) => {
        onSubmitCallback();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCorespondingWidget = (widget: IWidget) => {
    switch (widget.type) {
      case WIDGETS.WidgetTypes.workflowCount:
        return (
          <Col xs={6} sm={6} md={5} lg={3} xl={2}>
            <WorkflowExecutionCount
              widgetId={widget._id}
              workflowId={widget.workflow}
              siteId={siteId}
              onDelete={handleRemove}
              key={widget._id}
            />
          </Col>
        );
      case WIDGETS.WidgetTypes.visitorsToday:
        return (
          <Col xs={6} sm={6} md={5} lg={3} xl={2}>
            <VisitorsToday
              key={widget._id}
              widgetId={widget._id}
              siteId={siteId}
              onDelete={handleRemove}
            />
          </Col>
        );
      case WIDGETS.WidgetTypes.transportsToday:
        return (
          <Col xs={6} sm={6} md={5} lg={3} xl={2}>
            <TransportsToday
              key={widget._id}
              widgetId={widget._id}
              siteId={siteId}
              onDelete={handleRemove}
            />
          </Col>
        );
      case WIDGETS.WidgetTypes.visitorsWeek:
        return (
          <Col xs={12} sm={12} md={8} lg={6} xl={5}>
            <VisitorsWeek
              key={widget._id}
              widgetId={widget._id}
              siteId={siteId}
              onDelete={handleRemove}
            />
          </Col>
        );
      case WIDGETS.WidgetTypes.transportsWeek:
        return (
          <Col xs={12} sm={12} md={8} lg={6} xl={5}>
            <TransportsWeek
              key={widget._id}
              widgetId={widget._id}
              siteId={siteId}
              onDelete={handleRemove}
            />
          </Col>
        );
      default:
        return console.error(`${widget.type} widget is added but won't render`);
    }
  };

  return (
    <>
      <SimpleHeader
        name={
          <>
            <i className="fas fa-columns mr-1" />
            {loading ? <Skeleton width={90} /> : siteName}
          </>
        }
        button={
          // @ts-ignore TODO: fix type issue
          user.systemRoles.some((r) => ["admin", "contributor"].includes(r)) ? (
            <ActionItems
              elements={[
                {
                  icon: "edit",
                  tooltip: t("buttons.editSite"),
                  type: ActionItemTypes.Button,
                  click: () =>
                    deprecatedDispatch(
                      deprecatedOpenModal(SITE_MODAL, {
                        siteId: siteId,
                      })
                    ),
                },
                {
                  icon: "map-marker-alt",
                  tooltip: t("buttons.editSiteLocation"),
                  type: ActionItemTypes.Button,
                  click: () => dispatch(openModal({ type: MODALS.SiteLocation, siteId })),
                },
                {
                  icon: "id-badge",
                  tooltip: t("buttons.siteAccess"),
                  type: ActionItemTypes.Button,
                  click: () =>
                    dispatch(
                      openModal({
                        type: MODALS.SiteAccess,
                        siteId,
                      })
                    ),
                },
                {
                  icon: "plus",
                  tooltip: t("buttons.addWidget"),
                  type: ActionItemTypes.Button,
                  click: () =>
                    deprecatedDispatch(
                      deprecatedOpenModal(ADD_WIDGET_MODAL, {
                        siteId,
                        onSubmitCallback,
                      })
                    ),
                },
              ]}
              trigger={Triggers.Click}
              icon={<i className="fas fa-cog" />}
              link={false}
            />
          ) : null
        }
      ></SimpleHeader>
      <Container className="mt--6" fluid>
        <Row>
          {widgets.map((widget) => {
            return getCorespondingWidget(widget);
          })}
        </Row>
      </Container>
    </>
  );
}
