import * as yup from "yup";

const siteLocationSchema = yup.object().shape({
  latitude: yup
    .number()
    .required()
    .min(-90)
    .max(90),
  longitude: yup
    .number()
    .required()
    .min(-180)
    .max(180),
  radius: yup
    .number()
    .required()
    .min(0)
    .max(5000),
});

export default siteLocationSchema;
