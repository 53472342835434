import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Container } from "reactstrap";
import SimpleHeader from "../../components/Headers/SimpleHeader";
import { Link } from "react-router-dom";

import axios from "axios";
import { io } from "socket.io-client";

import { Icon, DivIcon } from "leaflet";

import { Map, TileLayer, Marker, Popup, Circle } from "react-leaflet";

import Pin from "../../assets/img/icons/pin.png";
import WarehouseSolid from "../../assets/img/icons/warehouse-solid.svg";

import { ILocationCoords } from "../../types/common";
import moment from "moment";
import { ISite } from "../../types/Site";

const garageIcon = new Icon({
  iconUrl: WarehouseSolid,
  iconSize: [32, 32],
});

const icon = new Icon({
  iconUrl: Pin,
  iconSize: [37, 104],
});

interface IUserLocation {
  _id: string;
  name: string;
  isNew?: boolean;
  lastKnownLocation: ILocationCoords;
}

const CentralOffice = () => {
  const { t } = useTranslation();

  const [users, setUsers] = useState<IUserLocation[]>([]);
  const [siteLocations, setSiteLocations] = useState<ISite[]>([]);

  useEffect(() => {
    axios.get<ISite[]>("/api/sites/locations").then((res) => {
      setSiteLocations(res.data);
    });

    axios
      .get<IUserLocation[]>("/api/location/users")
      .then((res) => {
        setUsers(res.data);
        const socket = io(process.env.REACT_APP_API_URL as string);

        socket.on("connect", () => {
          console.log("connect:", socket.id);
        });
        socket.on("connect_error", (err) => {
          console.log("connection error:", err.message);

          setTimeout(() => socket.connect(), 5000);
        });
        socket.on("location_update", (data: IUserLocation) => {
          console.log("data:", data);

          if (data.isNew) {
            setUsers((e) => [...e, data]);
          } else {
            setUsers((e) => e.map((user) => (user._id === data._id ? data : user)));
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    //  socket.on('disconnect',()=>setTime('server disconnected'))
    // return () => {
    //   socket.disconnect();
    // };
  }, []);

  return (
    <>
      <SimpleHeader
        name={
          <>
            <i className="fas fa-map-marked-alt mr-1" />
            {t("pages.centralOffice")}
          </>
        }
      />
      <Container className="mt--6" fluid>
        <Map
          center={[56.981506309089326, 24.393194314764013]}
          zoom={8}
          style={{ width: "100%", height: "calc(100vh - 250px)" }}
        >
          <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" />
          <TileLayer url="https://services.arcgisonline.com/ArcGIS/rest/services/Reference/World_Boundaries_and_Places/MapServer/tile/{z}/{y}/{x}" />
          {/* <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" /> */}
          {siteLocations.map((site) => {
            if (!site.location) {
              return;
            }

            const popup = (
              <Popup>
                <h5
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Link to={`/site/${site._id}/dashboard`}>
                    <Button className="btn-round btn-link" color="primary" size="sm">
                      <span className="btn-inner--icon">
                        <i className="fas fa-external-link-alt" />
                      </span>
                    </Button>
                  </Link>
                  {site.name}
                </h5>
                <ul
                  style={{
                    paddingLeft: 10,
                  }}
                >
                  <li>Address: {site.address}</li>
                  <li>Contact: {site.contactName}</li>
                  <li>Phone: {site.contactNumber}</li>
                  <li>Email: {site.contactEmail}</li>
                </ul>
              </Popup>
            );

            return [
              <Circle
                key={`${site._id}_circle`}
                center={[site.location.latitude, site.location.longitude]}
                radius={site.location.radius}
                color="#FFC482"
              >
                {popup}
              </Circle>,
              <Marker
                key={`${site._id}_marker}`}
                position={[site.location.latitude, site.location.longitude]}
                icon={garageIcon}
              >
                {popup}
              </Marker>,
            ];
          })}
          {users.map((user) => {
            const userIcon = new DivIcon({
              iconSize: [16, 16],
              className: "user-marker",
            });

            return (
              <Marker
                key={user._id}
                position={[user.lastKnownLocation.latitude, user.lastKnownLocation.longitude]}
                // @ts-ignore
                icon={userIcon}
              >
                <Popup>
                  Name: {user.name}
                  <br />
                  Speed: {user.lastKnownLocation.speed}
                  <br />
                  {user.lastKnownLocation.timestamp ? (
                    <>LastUpdate: {moment(user.lastKnownLocation.timestamp).format()}</>
                  ) : null}
                </Popup>
              </Marker>
            );
          })}
        </Map>
      </Container>
    </>
  );
};

export default CentralOffice;
