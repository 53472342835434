import React, { useCallback, useEffect, useState } from "react";

// constants & types
import { ISiteLocationModalProps } from "../types/Modal";
import { MODULES } from "../constants/modules";
import { ROLE_PERMISSIONS } from "../constants/rolePermissions";

// utils
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useAppDispatch } from "../hooks/redux";
import { closeModalById } from "../features/modals";

// components
import { Map, TileLayer, Circle } from "react-leaflet";
import { Button, Col, Container, Form, Modal, Row } from "reactstrap";
import { Formik } from "formik";

import PermissionGuard from "../components/PermissionGuard";
import SpinnerButton from "../components/Buttons/SpinnerButton";
import LabelFormGroup from "../components/Inputs/LabelFormGroup";
import LoadingOverlay from "../components/LoadingOverlay";
import siteLocationSchema from "../schemas/siteLocation";

export default function SiteLocationModal(props: ISiteLocationModalProps) {
  const { id, siteId } = props;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [initialValues, setInitialValues] = useState<{
    latitude: number;
    longitude: number;
    radius: number;
  }>();

  const loadData = useCallback(() => {
    setIsLoading(true);
    axios
      .get<{
        latitude: number;
        longitude: number;
        radius: number;
      }>(`/api/sites/${siteId}/location`)
      .then((response) => {
        setInitialValues(response.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setSubmitting(false);
      });
  }, [siteId]);

  const onSubmit = useCallback(
    (values: any) => {
      setSubmitting(true);
      axios
        .post(`/api/sites/${siteId}/location`, values)
        .then((response) => {
          // callback(response.data);
          close();
        })
        .catch((err) => {
          setSubmitting(false);
        });
    },
    [siteId]
  );

  const close = () => {
    dispatch(closeModalById(id));
  };

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <Modal className="modal-dialog-centered" isOpen={true} toggle={close} size="lg">
      <LoadingOverlay isLoading={isLoading}>
        <Formik
          enableReinitialize
          initialValues={initialValues ? initialValues : { latitude: 0, longitude: 0, radius: 0 }}
          validationSchema={siteLocationSchema}
          onSubmit={onSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            handleSubmit,
            dirty,
          }) => (
            <Form role="form" onSubmit={handleSubmit}>
              <div className="modal-header">
                <h6 className="modal-title">{t("modalTitles.siteLocation")}</h6>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={close}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <div className="modal-body">
                <Container>
                  <Row>
                    <Col xs={5}>
                      <LabelFormGroup
                        name="latitude"
                        label={t("fields.latitude")}
                        value={values.latitude}
                        onChange={handleChange}
                        touched={touched.latitude}
                        validationErrors={errors.latitude}
                      />
                    </Col>
                    <Col xs={5}>
                      <LabelFormGroup
                        name="longitude"
                        label={t("fields.longitude")}
                        value={values.longitude}
                        onChange={handleChange}
                        touched={touched.longitude}
                        validationErrors={errors.longitude}
                      />
                    </Col>
                    <Col xs={2}>
                      <LabelFormGroup
                        name="radius"
                        label={t("fields.radius")}
                        value={values.radius}
                        onChange={handleChange}
                        touched={touched.radius}
                        validationErrors={errors.radius}
                      />
                    </Col>
                  </Row>
                </Container>
                <Map
                  center={
                    initialValues
                      ? [initialValues.latitude, initialValues.longitude]
                      : [56.981506309089326, 24.393194314764013]
                  }
                  zoom={initialValues ? 14 : 8}
                  scrollWheelZoom={true}
                  style={{ width: "100%", minHeight: 400 }}
                  onclick={(e) => {
                    setFieldValue("latitude", e.latlng.lat);
                    setFieldValue("longitude", e.latlng.lng);
                  }}
                >
                  <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" />
                  <TileLayer url="https://services.arcgisonline.com/ArcGIS/rest/services/Reference/World_Boundaries_and_Places/MapServer/tile/{z}/{y}/{x}" />
                  {/* <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" /> */}

                  <Circle
                    center={[values.latitude, values.longitude]}
                    radius={values.radius}
                    color="#FFC482"
                  />
                </Map>
              </div>
              <div className="modal-footer">
                <Button
                  className="ml-auto"
                  color="link"
                  data-dismiss="modal"
                  type="button"
                  onClick={close}
                >
                  {t("buttons.close")}
                </Button>
                <PermissionGuard module={MODULES.Workflows} permission={ROLE_PERMISSIONS.WRITE}>
                  {({ hasAccess }) => (
                    <SpinnerButton
                      isLoading={submitting}
                      disabled={!hasAccess || !dirty}
                      type="submit"
                      color="primary"
                    >
                      {t("buttons.save")}
                    </SpinnerButton>
                  )}
                </PermissionGuard>
              </div>
            </Form>
          )}
        </Formik>
      </LoadingOverlay>
    </Modal>
  );
}
