enum MODALS {
  Role = "Role",
  WorkflowSites = "WorkflowSites",
  Visitor = "Visitor",
  ScheduleVisitor = "ScheduleVisitor",
  CalendarDate = "CalendarDate",
  Transport = "Transport",
  Qr = "Qr",
  GeolocationCollection = "GeolocationCollection",
  Map = "Map",
  SiteAccess = "SiteAccess",
  SiteLocation = "SiteLocation",
  WorkflowAddStep = "WorkflowAddStep",
  WorkflowDuplicate = "WorkflowDuplicate",
}

export default MODALS;
