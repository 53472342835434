import React, { useEffect, useState } from "react";

import workspaceSchema from "../../schemas/workspace";

import axios from "axios";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setUserData } from "../../actions/authentication";

import { Formik } from "formik";
import {
  Card,
  CardHeader,
  Container,
  Form,
  Row,
  Col,
  CardBody,
  UncontrolledTooltip,
  Button,
} from "reactstrap";

import SimpleHeader from "../../components/Headers/SimpleHeader";
import LabelFormGroup from "../../components/Inputs/LabelFormGroup";
import SpinnerButton from "../../components/Buttons/SpinnerButton";
import LabelSingleFile from "../../components/Inputs/LabelSingleFile";
import Thumbnail from "../../components/Thumbnail";
import LoadingOverlay from "../../components/LoadingOverlay";

export default function Settings(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);

  const [isSubmitting, setSubmitting] = useState(false);
  const [isLogoSubmitting, setLogoSubmitting] = useState(false);
  const [isLogoDeleting, setLogoDeleting] = useState(false);
  const [logo, setLogo] = useState();

  const onSubmit = (values) => {
    setSubmitting(true);

    axios
      .put("/api/workspace", values)
      .then((res) => {
        dispatch(setUserData({ ...user, workspace: { ...user.workspace, ...values } }));
        setSubmitting(false);
      })
      .catch((err) => {
        setSubmitting(false);
      });
  };

  const uploadLogo = (file) => {
    setLogoSubmitting(true);
    setLogo(file[0]);
    let fd = new FormData();

    fd.append("file", file[0]);
    axios
      .post("/api/workspace/logo", fd, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        console.log("res:", res);
        dispatch(setUserData({ ...user, workspace: res.data }));
        setLogoSubmitting(false);
      })
      .catch((err) => {
        console.log("err", err);
        setLogoSubmitting(false);
      });
  };

  const deleteLogo = () => {
    setLogoDeleting(true);
    axios
      .delete("/api/workspace/logo")
      .then((res) => {
        console.log("res:", res);
        dispatch(setUserData({ ...user, workspace: res.data }));
        setLogoDeleting(false);
      })
      .catch((err) => {
        console.log("err", err);
        setLogoDeleting(false);
      });
  };

  useEffect(() => {
    if (user.workspace.logo) {
      setLogo(`${process.env.REACT_APP_API_URL}uploads/${user.workspace.logo.key}`);
    } else {
      setLogo(null);
    }
  }, [user.workspace.logo]);

  return (
    <>
      <SimpleHeader
        name={
          <>
            <i className="fas fa-cogs mr-1" />
            {t("pages.settings")}
          </>
        }
      />
      <Container className="mt--6" fluid>
        <Row>
          <Col>
            <Card>
              <CardHeader className="border-0"></CardHeader>
              <CardBody>
                <Row>
                  <Col xs={12}>
                    <Formik
                      initialValues={{
                        name: user.workspace.name,
                      }}
                      validationSchema={workspaceSchema}
                      onSubmit={onSubmit}
                      enableReinitialize
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        setFieldValue,
                        handleSubmit,
                        dirty,
                      }) => (
                        <Form>
                          <Row>
                            <Col>
                              <LabelFormGroup
                                label={t("fields.workspaceName", "Name")}
                                name="name"
                                value={values.name}
                                onChange={handleChange}
                                touched={touched.name}
                                validationErrors={errors.name}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <LabelSingleFile
                                label={t("fields.workspaceLogo", "Logo")}
                                callback={uploadLogo}
                                disabled={isLogoSubmitting}
                              />
                            </Col>
                          </Row>
                          {logo ? (
                            <Row>
                              <Col xs="auto">
                                <LoadingOverlay isLoading={isLogoSubmitting || isLogoDeleting}>
                                  <Thumbnail value={logo} />
                                </LoadingOverlay>
                              </Col>
                              {!isLogoSubmitting ? (
                                <Col className="d-flex align-items-center" xs="auto">
                                  <Button
                                    outline
                                    className="btn-round btn-icon"
                                    color="danger"
                                    id="deleteLogo"
                                    onClick={deleteLogo}
                                    size="sm"
                                    disabled={isLogoDeleting}
                                  >
                                    <span className="btn-inner--icon">
                                      <i className="fas fa-times" />
                                    </span>
                                  </Button>
                                  <UncontrolledTooltip delay={0} target="deleteLogo">
                                    {t("tooltips.deleteLogo", "Delete logo")}
                                  </UncontrolledTooltip>
                                </Col>
                              ) : null}
                            </Row>
                          ) : null}
                          <Row>
                            <Col className="text-right">
                              <SpinnerButton
                                isLoading={isSubmitting}
                                type="submit"
                                className="ml-auto"
                                color="primary"
                                onClick={handleSubmit}
                                disabled={!dirty}
                              >
                                {t("buttons.save")}
                              </SpinnerButton>
                            </Col>
                          </Row>
                        </Form>
                      )}
                    </Formik>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
